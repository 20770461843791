import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import ProductListItem from "../components/ProductListItem";
import SEO from "../components/seo";
import homeStyles from "../styles/home.module.css";
import "../styles/home.css";
import ArrowDecor from "../components/home/ArrowDecor";
import HeaderBottom from "../components/HeaderBottom";
import Footer from "../components/Footer";

const MainShowcasePageTemplate = ({ data, pageContext, location }) => {
  const page = data.markdownRemark.frontmatter;
  const siteTitle = data.site.siteMetadata.title;
  const siteUrl = data.site.siteMetadata.siteUrl;
  const { previous, next } = pageContext;

  const products = data.allMarkdownRemark.edges;
  console.log("productz", products, page);

  return (
    <Layout location={location} title={siteTitle}>
      <Helmet
        bodyAttributes={{
          class: "site-layout--horizontal scroll-is--active",
        }}
      />
      {/* <div id="particles-background" class="vertical-centered-box"></div>
      <div id="particles-foreground" class="vertical-centered-box"></div> */}
      <HeaderBottom />
      <main class="site-content" id="wrapper">

      <div class="widget-area widger-area--before-loop">
        <div class="widget widget-carousel slick-slider">
          <article class="widget-carousel__item player bg-image bg--ph-03">
            <div class="player__subtitle h6">Welcome</div>
            <h1 class="player__title h3 color-primary">JULIE_NOIRE</h1>
            <div class="player__body">
              <div class="h5">To the <span class="color-primary">Xenovrese</span> team!</div>
            </div>
            <div class="player__thumbnail">
              <img src="/assets/img/samples/team-info-page-player-photo-03.png" alt=""/>
              <a href="team-player-3.html" class="more-info-link h6"><span class="add-icon"></span>More info</a>
            </div>
          </article>
          <article class="widget-carousel__item player bg-image bg--ph-05">
            <div class="player__subtitle h6">Congratulations!</div>
            <h1 class="player__title h3">Crazzzy_80</h1>
            <div class="player__body">
              <div class="h5 color-primary">Reached 10k twitch subs</div>
            </div>
            <div class="player__thumbnail">
              <img src="/assets/img/samples/team-info-page-player-photo-04.png" alt=""/>
              <a href="team-player-4.html" class="more-info-link h6"><span class="add-icon"></span>More info</a>
            </div>
          </article>
          <article class="widget-carousel__item player bg-image bg--ph-06">
            <div class="player__subtitle h6">Featured player</div>
            <h1 class="player__title h3 color-warning">G-Huntress</h1>
            <div class="player__body">
              <ul class="player__stats">
                <li class="counter">
                  <div class="counter__number">28</div>
                  <div class="counter__label">Games</div>
                </li>
                <li class="counter">
                  <div class="counter__number">73</div>
                  <div class="counter__label">Kills</div>
                </li>
                <li class="counter">
                  <div class="counter__number">96</div>
                  <div class="counter__label">Win %</div>
                </li>
              </ul>
            </div>
            <div class="player__thumbnail">
              <img src="/assets/img/samples/team-info-page-player-photo-02.png" alt=""/>
              <a href="team-player-2.html" class="more-info-link h6"><span class="add-icon"></span>More info</a>
            </div>
          </article>
        </div>
      </div>
      <div class="content blog-layout--style-1">

        <article class="post has-post-thumbnail ">
          <div class="post__thumbnail">

            <a href="blog-post.html"><img src="/assets/img/samples/news-v1-post-img-01.jpg" alt=""/></a>


          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-warning">
                  <a href="#">Gaming</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">League of heroes presented a new character</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">August 15th, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>
        <article class="post has-post-thumbnail ">
          <div class="post__thumbnail">

            <a href="blog-post.html"><img src="/assets/img/samples/news-v1-post-img-02.jpg" alt=""/></a>


          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-success">
                  <a href="#">eSports</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">The Necromancers emerge victorious once more!</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">August 14th, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>
        <article class="post has-post-thumbnail ">
          <div class="post__thumbnail">

            <a href="blog-post.html"><img src="/assets/img/samples/news-v1-post-img-03.jpg" alt=""/></a>


          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-success">
                  <a href="#">eSports</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">Matt Rodgers talks about becoming a pro gamer</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">August 10th, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>
        <article class="post has-post-thumbnail ">
          <div class="post__thumbnail">

            <a href="blog-post.html"><img src="/assets/img/samples/news-v1-post-img-04.jpg" alt=""/></a>


          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-warning">
                  <a href="#">Gaming</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">Magimons will have a new class: Pyromancer</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">August 8th, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>
        <article class="post has-post-thumbnail  post--video">
          <div class="post__thumbnail">


            <a href="https://www.youtube.com/watch?v&#x3D;XE0fU9PCrWE" class="mp_iframe">
              <img src="/assets/img/samples/news-v4-post-img-04.jpg" alt=""/>
              <div class="post__thumbnail-icon">
                <span class="lightbox__icon"></span>
              </div>
            </a>

          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-danger">
                  <a href="#">Videos</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">Jack shows a sneak peak of the new loh expansion</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">August 3rd, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>
        <article class="post has-post-thumbnail ">
          <div class="post__thumbnail">

            <a href="blog-post.html"><img src="/assets/img/samples/news-v4-post-img-06.jpg" alt=""/></a>


          </div>
          <div class="post__body">
            <div class="post__header">
              <ul class="post__cats list-unstyled">
                <li class="post__cats-item color-success">
                  <a href="#">eSports</a>
                </li>
              </ul>
              <h2 class="post__title h4"><a href="blog-post.html">The team tested the new vr pod at 2018 gamexcon</a></h2>
              <ul class="post__meta list-unstyled">
                <li class="post__meta-item post__meta-item--date">
                  <a href="#">July 24th, 2018</a>
                </li>
              </ul>
            </div>
            <div class="post__excerpt">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Suscipit mollitia modi, tempore, pariatur, error quaerat dolorum laborum, commodi laudantium soluta sit reiciendis praesentium dolore cumque ab.
            </div>
          </div>
        </article>

      </div>
    </main>
    </Layout>
  );
};

export default MainShowcasePageTemplate;

const InstaFeed = () => {
  return (
    <div id="instagramFeed" className="full-width">
      <div class="instagram_gallery">
        <a
          href="https://www.instagram.com/p/Bh-P3IoDxyB"
          rel="noopener"
          target="_blank"
        >
          <img src="..." alt="instagram instagram image 0" />
        </a>
        ...
      </div>
    </div>
  );
};

export const pageQuery = graphql`
  query MainShowcasePageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    markdownRemark(frontmatter: { id: { eq: "home" } }) {
      frontmatter {
        templateKey
        logo
        companyName
        waNum
        waMessage
        email
        instaStoreUrl
        companyAddress
        messageParagraph {
          messageBody
          messageHeader
        }
        testimonials {
          image
          text
          name
        }
        brands {
          image
          brandUrl
        }
        heroSlider {
          slide1 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
          slide2 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
          slide3 {
            image
            itemname_one
            itemname_two
            itemname_three
            buttonText
            buttonUrl
          }
        }
        id
        videoUrl
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "product" }, isHighlight: { eq: true } }
      }
    ) {
      edges {
        node {
          frontmatter {
            name
            price
            date(locale: "id")
            thumbnail
            images {
              image
            }
            tag
            redLabel
            blackLabel
            tokpedUrl
            instaUrl
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;

import React, { Component } from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

const ProductListItem = (props) => {
  let data = useStaticQuery(graphql`
    query ProductListItemQuery {
      markdownRemark(frontmatter: { id: { eq: "home" } }) {
        frontmatter {
          waNum
          waMessage
        }
      }
    }
  `);
  data = data.markdownRemark.frontmatter;

  // Generate WA URL
  let waUrl = `https://wa.me/${data.waNum.replace(/^0/g, "62")}`;
  let waMessage = data.waMessage.replace("<url>", props.origin + props.slug);
  waUrl = waUrl + `?text=${encodeURI(waMessage)}`;

  return (
    <div class="product">
      <Link
        to={props.slug}
        alt="product thumbnail"
        className="product__thumbnail"
      >
        {/* <img src="/assets/img/samples/product-img-01.png" alt="" /> */}
        <img src={props.prod.thumbnail} alt="product thumbnail" />
        <a
          href={waUrl}
          target="__blank"
          title="Whatsapp"
          style={{ pointerEvents: waUrl == "" ? "none" : "auto" }}
          class="btn btn-tertiary"
        >
          Ask On Whatsapp
        </a>
      </Link>
      <div class="product__body">
        <div class="product__header">
          <ul class="product__cats list-unstyled">
            <li class="product__cats-item">
              <a href="#">{props.prod.tag.split("-")[1]}</a>
            </li>
          </ul>
          <h2 class="product__title h4">
            <Link to={props.slug} alt="product details link">
              {props.prod.name}
            </Link>
          </h2>
          <ul class="product__meta list-unstyled">
            <li class="product__meta-item product__price">
              {new Intl.NumberFormat("id", {
                style: "currency",
                currency: "IDR",
              }).format(parseFloat(props.prod.price))}
            </li>
            <li class="product__meta-item product__ratings">
              <div class="fas star-rating">
                <span style={{ width: "100%" }}>&nbsp;</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ProductListItem;
